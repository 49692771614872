import React from "react"
import { graphql } from "gatsby"
import { ContentWrapper } from "../../components"

import Layout from "../../layouts/fr"

const GeneratedPageFR = props => {
  const {
    html,
    excerpt,
    frontmatter: { title },
  } = props.data.markdownRemark

  return (
    <Layout location={props.location} title={title} description={excerpt}>
      <ContentWrapper title={title} markdownHTML={html} />
    </Layout>
  )
}

export default GeneratedPageFR

export const query = graphql`
  query GeneratedPageFR($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(format: PLAIN)
      frontmatter {
        title
      }
    }
  }
`
